.gallery-image {
    max-width: 400px;
    width: auto;
    border: 2px solid black;
    padding: 1px;
    margin: 2px;
    border-radius: 5px;
    height: auto;
    transition: all 0.2s ease-in-out;
}

.gallery-image:hover {
    border: 1px solid skyblue;
    /* filter: drop-shadow(8px 8px 10px gray) sepia(100%) saturate(30%); */
    border-radius: 50%;

    max-width: 402px;
    ;
}
.gallery-image:hover::after {
}
.image-modal {
    display: none;
    position: fixed;

    left: 0;
    top: 0;
    width: 100%;
    margin-top: 10px;
    overflow: auto;
 
}

.modal-content {
    background-color: #b6acac;
    margin: auto;
    padding: 10px;
    border: 1px solid #888;
    max-width: 80%;
    text-align: center;
    height: auto;
    
}
.modal-content img{
    text-align: center;
    margin-left: 100px;
    border: 2px solid white;
    border-radius: 20px;
}
.modal-content:hover  .close{
    font-size: x-large;
    color: black;
}

.close {
    color: #aaa;
    float: left;
    font-size: large;
    font-weight: bold;
    transition: all .2s ease-in-out;
}

.close:hover,
.close:focus {
    color: black;
    font-size: x-large;
    text-decoration: none;
    cursor: pointer;
}