.comparison-slider img {
    width: 100%;
    height: auto;
    vertical-align: middle;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}

.comparison-slider {
    margin: 20px auto;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    box-shadow: 0 15px 15px rgb(0 0 0 / 25%);
}

.comparison-item {
    overflow: hidden;
}

.comparison-item.top {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
}

.comparison-item.top img {
    height: 100%;
    object-fit: cover;
}

.handle {
    position: absolute;
    width: 10px;
    height: 100%;
    background: rgb(24, 21, 21);
    z-index: 3;
    cursor: col-resize;
    top: 0;
}

.handle svg {
    display: block;
    position: absolute;
    top: calc(50% - 15px);
    right: calc(50% - 15px);
    height: 30px;
    width: 30px;
    background: white;
    border-radius: 8px;
    padding: 2px;
}

.service-description {
    width: 50%;
    margin: auto;
    offset-position: 200px;
    font-size: larger;
    font-family: cursive;
}

.service-container {
    margin: auto;
    background: rgb(199, 38, 38);
    background: rgba(42, 42, 43, 0.5);
    color: #f1f1f1;
    width: 100%;
    max-width: 95%;
    padding: 20px;
    margin-bottom: 8px;
    box-shadow: 3px 3px 2px gray;
    border-radius: 5px;
}
