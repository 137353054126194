a {
    text-decoration: none;
    color: white;
    font-size: small;
    transition: all 0.2s ease-in-out;
    margin: 10px
}

a:hover {
    color: rgb(225, 119, 31);
}

.footer {
    color: rgb(247, 240, 240);
    width: 100%;
    margin: auto;
    display: flex;
    font-size: small;
    background-attachment: scroll;
    background-position: 0% 0%;
     text-decoration: dashed;
    position: absolute;
    bottom: 0pt;
    left: 0pt;
    background-color: black;
    padding: 10px 5px;
    text-align: center;
    align-self: flex-end; 
}
.footer img{
    padding: 5px;
}

.footer p {
    margin: 10px;
}