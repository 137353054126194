.outer-box {
    /* Set a specific height */
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: auto;
    width: 100%;

}

.outer-box img {
    filter: brightness(50%);
    max-width: 100%;
    width: 100%;
    margin: auto;
    height: auto;
}
.contact-details img{
    filter: brightness(100%);
    max-width: 100%;
    width: 100%;
 text-shadow: 10px 10px 10px black;
    margin: auto;
    height: auto;
}
.contact-details h1{
    text-shadow: 20px 20px 20px black;
}
ul{
    list-style-type: circle;
    align-items: start;
    text-align: start;
}

.contact-details {
    text-align: center;
    position: absolute;
    top: 35%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: white;
    border-radius: 20px;
    width: "20%";
    padding: 15px;
    border: 1px solid gray;
    background: rgba(255,255,255,0.2);
}